import { setFaqs, setIsFaqLoading } from "../redux/slice/helpAndPoliciesSlice";
import store from "../redux/store";
import { getAPIData } from "../utilServices/utils";

export const getFaqs = async () => {
    try {
        store.dispatch(setIsFaqLoading(true));
        const faqsResponse = await getAPIData({
            url: `business/utils/faqs`,
        });
        if (faqsResponse.data.message === "Request Successful") {
            store.dispatch(setFaqs(faqsResponse.data.data));
        }
        store.dispatch(setIsFaqLoading(false));
    } catch (error: any) {
        console.log(error.message);
        store.dispatch(setIsFaqLoading(false));
    } finally {
        store.dispatch(setIsFaqLoading(false));
    }
};
