import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isSalesLoading: false,
    isTableLoading: false,
    creatorsData: null,
    topCreators: null,
    topProducts: null,
    monthlySales: null,
    totalSaleAmount: null,
    overallSalesData: null,
    userWiseSales: null,
    pageNumber: 1,
    totalPages: null,
    pageSize: 50,
    startDate: new Date(Date.now() - 1000 * 60 * 60 * 24 * 90)
        .toISOString()
        .split("T")[0],
    endDate: new Date(Date.now()).toISOString().split("T")[0],
    sortSelectedKey: null,
    salesTableColumns: null,
};

const salesSlice = createSlice({
    name: "sales",
    initialState,
    reducers: {
        setSalesLoading: (state, action) => {
            state.isSalesLoading = action.payload;
        },
        setTableLoading: (state, action) => {
            state.isTableLoading = action.payload;
        },
        setCreatorsData: (state, action) => {
            state.creatorsData = action.payload;
        },
        setTopCreators: (state, action) => {
            state.topCreators = action.payload;
        },
        setTopProducts: (state, action) => {
            state.topProducts = action.payload;
        },
        setMonthlySales: (state, action) => {
            state.monthlySales = action.payload;
        },
        setTotalSalesAmount: (state, action) => {
            state.totalSaleAmount = action.payload;
        },
        setOverallSalesData: (state, action) => {
            state.overallSalesData = action.payload;
        },
        setUserWiseSalesData: (state, action) => {
            state.userWiseSales = action.payload;
        },
        setPageNumber: (state, action) => {
            state.pageNumber = action.payload;
        },
        setTotalPages: (state, action) => {
            state.totalPages = action.payload;
        },
        setPageSize: (state, action) => {
            state.pageSize = action.payload;
        },
        setStartDate: (state, action) => {
            state.startDate = action.payload;
        },
        setEndDate: (state, action) => {
            state.endDate = action.payload;
        },
        setSortSelectedKey: (state, action) => {
            state.sortSelectedKey = action.payload;
        },
        setSalesTableColumns: (state, action) => {
            state.salesTableColumns = action.payload;
        },
        clearSalesState: () => {
            return initialState;
        },
    },
});

export const {
    setSalesLoading,
    setTableLoading,
    setCreatorsData,
    setTopCreators,
    setTopProducts,
    setMonthlySales,
    setTotalSalesAmount,
    setOverallSalesData,
    setUserWiseSalesData,
    setPageNumber,
    setTotalPages,
    setPageSize,
    setStartDate,
    setEndDate,
    setSortSelectedKey,
    setSalesTableColumns,
    clearSalesState,
} = salesSlice.actions;
export const selectSales = (state: { sales: any }) => state.sales;
export default salesSlice.reducer;
