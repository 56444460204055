import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isAuthenticated: false,
    user: {},
    loginMessage: null,
    showLoginErrorModal: false,
    loginLoading: false,
    userBackgroundIndex: null,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login: (state, action) => {
            state.isAuthenticated = action.payload?.user?._id ? true : false;
            state.user = action.payload.user;
        },
        setLoginMessage: (state, action) => {
            state.loginMessage = action.payload;
        },
        updateUser: (state, action) => {
            state.user = action.payload;
        },
        updateUserProperties: (state, action) => {
            state.user = { ...state.user, ...action.payload };
        },
        logout: (state) => {
            state.isAuthenticated = false;
            state.user = {};
        },
        setShowLoginErrorModal: (state, action) => {
            state.showLoginErrorModal = action.payload;
        },
        setLoginLoading: (state, action) => {
            state.loginLoading = action.payload;
        },
        setUserBackgroundIndex: (state, action) => {
            state.userBackgroundIndex = action.payload;
        },
    },
});

export const {
    login,
    setLoginMessage,
    updateUser,
    updateUserProperties,
    logout,
    setShowLoginErrorModal,
    setLoginLoading,
    setUserBackgroundIndex,
} = authSlice.actions;
export const selectAuth = (state: { auth: any }) => state.auth;
export default authSlice.reducer;
