import { postAPIData } from "../utilServices/utils";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth } from "../utilServices/firebase";
import store from "../redux/store";
import {
    login,
    setLoginLoading,
    setLoginMessage,
    setShowLoginErrorModal,
    setUserBackgroundIndex,
} from "../redux/slice/authSlice";
import { backgroundList } from "../utilServices/utils";

export const googleSignIn = async () => {
    try {
        store.dispatch(setLoginLoading(true));
        const provider = new GoogleAuthProvider();
        const resp = await signInWithPopup(auth, provider);
        const idToken = await resp.user.getIdToken();
        const obj = {
            idToken: idToken,
        };
        const loginResponse = await postAPIData(
            {
                url: "business/auth/google/login",
                data: obj,
            },
            false
        );
        store.dispatch(
            login({
                user: loginResponse?.data.data,
            })
        );
        store.dispatch(setLoginMessage(loginResponse?.data.message));
        store.dispatch(setLoginLoading(false));
        store.dispatch(
            setUserBackgroundIndex(
                Math.floor(Math.random() * backgroundList?.length)
            )
        );
        if (loginResponse?.data?.code !== 200) {
            store.dispatch(setShowLoginErrorModal(true));
        }
    } catch (err) {
        store.dispatch(setLoginLoading(false));
        // message.error(err?.message || "Something went wrong");
    } finally {
        store.dispatch(setLoginLoading(false));
        // setLoading(false);
    }
};
