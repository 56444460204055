import React, { useState, useRef, useEffect } from "react";
import NameInitials from "./../NameInitials";
import { useSelector } from "react-redux";
import styles from "./index.module.css";
import logoutOpen from "./../../../assets/logout/logoutOpen.png";
import logoutClose from "./../../../assets/logout/logoutClose.png";
import logoutIcon from "./../../../assets/logout/logoutIcon.png";
import { roles } from "../../../enums";

const Index = () => {
    const { user, userBackgroundIndex } = useSelector(
        (state: any) => state.root.auth
    );
    const [isOpen, setIsOpen] = useState(false);
    const logoutRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (
            logoutRef.current &&
            !logoutRef.current.contains(event.target as Node)
        ) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        window.addEventListener("mousedown", handleClickOutside);

        return () => {
            window.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div
            className={styles.logoutContainer}
            onClick={() => {
                setIsOpen(!isOpen);
            }}
            ref={logoutRef}
        >
            <div className={styles.dpContainer}>
                <NameInitials
                    name={user.name}
                    size={"45px"}
                    fontSize={"20px"}
                    userBackgroundIndex={userBackgroundIndex}
                />
            </div>
            <img
                className={styles.dropdownArrow}
                src={isOpen ? logoutClose : logoutOpen}
                alt='arrow'
            />
            {isOpen && (
                <div className={styles.logoutMenuWrapper}>
                    <div className={styles.infoWrapper}>
                        <NameInitials
                            name={user.name}
                            size={"45px"}
                            fontSize={"20px"}
                            userBackgroundIndex={userBackgroundIndex}
                        />
                        <div className={styles.info}>
                            <h5>
                                {user.name?.length > 16
                                    ? user.name.slice(0, 16) + "..."
                                    : user.name}
                            </h5>
                            <p>{roles[user.role].toUpperCase()}</p>
                        </div>
                    </div>
                    <div className={styles.logoutBtnWrapper}>
                        <button
                            onClick={() => {
                                setIsOpen(!isOpen);
                                localStorage.clear();
                                window.location.reload();
                            }}
                        >
                            <img src={logoutIcon} alt='logout icon' />
                            Logout
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Index;
