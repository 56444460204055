import { createSlice } from "@reduxjs/toolkit";

type MembersList = any[];

interface InitialState {
    membersList: MembersList;
    isHelpAndPoliciesLoading: boolean;
    userInviteList: any[];
    isFaqLoading: boolean;
    faqs: any[];
}

const initialState: InitialState = {
    membersList: [],
    isHelpAndPoliciesLoading: false,
    userInviteList: [],
    isFaqLoading: false,
    faqs: [],
};

const helpAndPoliciesSlice = createSlice({
    name: "helpAndPolicies",
    initialState,
    reducers: {
        setMembersList: (state, action) => {
            state.membersList = action.payload;
        },
        updateMembersList: (state, action) => {
            state.membersList = [...state.membersList, action.payload];
        },
        setIsHelpAndPoliciesLoading: (state, action) => {
            state.isHelpAndPoliciesLoading = action.payload;
        },
        setIsFaqLoading: (state, action) => {
            state.isFaqLoading = action.payload;
        },
        setFaqs: (state, action) => {
            state.faqs = action.payload;
        },
        clearHelpAndPoliciesState: () => {
            return initialState;
        },
    },
});

export const {
    setMembersList,
    setIsHelpAndPoliciesLoading,
    updateMembersList,
    setIsFaqLoading,
    setFaqs,
} = helpAndPoliciesSlice.actions;
export const selectHelpAndPolicies = (state: { helpAndPolicies: any }) =>
    state.helpAndPolicies;
export default helpAndPoliciesSlice.reducer;
