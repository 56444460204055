import React from "react";
import styles from "./index.module.css";
import Loader from "./../Loader";

const Index = () => {
    return (
        <div className={styles.overlay}>
            <div className={styles.loaderContainer}>
                <Loader length={"3em"} />
            </div>
        </div>
    );
};

export default Index;
