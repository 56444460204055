import React, { useState } from "react";
import styles from "./index.module.css";
import { useSelector, useDispatch } from "react-redux";
import {
    setOverallSalesData,
    setSortSelectedKey,
} from "../../../redux/slice/salesSlice";
import {
    setOverallOverviewData,
    setOverallSortKey,
} from "../../../redux/slice/overviewSlice";
import {
    setCampaignSortKey,
    setOverallCampaignData,
} from "../../../redux/slice/campaignsSlice";

interface props {
    setIsSortOpen: any;
    sortDropDownElements: any;
    page: any;
}

export const salesDataKeyMapping: {
    Followers: string;
    "Total Sales": string;
    Amount: string;
} = {
    Followers: "cummulativeFollowers",
    "Total Sales": "totalSales",
    Amount: "totalSalesAmount",
};

export const overviewDataKeyMapping: {
    Followers: string;
    Content: string;
    Views: string;
    Likes: string;
    Comments: string;
    "Auto DM Sent": string;
    Amount: string;
    "Website Visits": string;
} = {
    Followers: "cummulativeFollowers",
    Content: "contentPieces",
    Views: "views",
    Likes: "likes",
    Comments: "comments",
    "Auto DM Sent": "dms",
    Amount: "totalSalesAmount",
    "Website Visits": "websiteVisits",
};

export const campaignDataKeyMapping: {
    Followers: string;
    Views: string;
    Likes: string;
    Comments: string;
    "Eng. Rate": string;
    "Auto DM Sent": string;
    "Con. Rate": string;
    Amount: string;
    "Website Visits": string;
} = {
    Followers: "cummulativeFollowers",
    Views: "views",
    Likes: "likes",
    Comments: "comments",
    "Eng. Rate": "engagementRate",
    "Auto DM Sent": "dms",
    "Con. Rate": "saleConversionRate",
    Amount: "totalSalesAmount",
    "Website Visits": "websiteVisits",
};

const Index: React.FC<props> = ({
    setIsSortOpen,
    sortDropDownElements,
    page,
}) => {
    const { overallSalesData, sortSelectedKey } = useSelector(
        (state: any) => state.root.sales
    );
    const { overallOverviewData } = useSelector(
        (state: any) => state.root.overview
    );
    const { overallCampaignData } = useSelector(
        (state: any) => state.root.campaigns
    );
    const dispatch = useDispatch();
    const [isAsc, setIsAsc] = useState<any>(false);

    type SalesDataKey = keyof typeof salesDataKeyMapping;
    type OverviewDataKey = keyof typeof overviewDataKeyMapping;
    type CampaignDataKey = keyof typeof campaignDataKeyMapping;
    type PageKey = SalesDataKey | OverviewDataKey;
    const handelSortClick = async (ele: PageKey) => {
        const data: any[] = [];
        setIsSortOpen(false);
        if (page.toLowerCase() === "sales") {
            overallSalesData.forEach((item: any) => {
                data.push({ ...item });
            });
            const key = ele as SalesDataKey;
            dispatch(setSortSelectedKey(salesDataKeyMapping[key]));
            dispatch(
                setOverallSalesData(
                    data.sort((a, b) => {
                        const aValue = Number(a[salesDataKeyMapping[key]]);
                        const bValue = Number(b[salesDataKeyMapping[key]]);
                        return isAsc ? aValue - bValue : bValue - aValue;
                    })
                )
            );
        } else if (page.toLowerCase() === "overview") {
            const key = ele as OverviewDataKey;
            overallOverviewData.forEach((item: any) => {
                data.push({ ...item });
            });
            dispatch(setOverallSortKey(overviewDataKeyMapping[key]));
            dispatch(
                setOverallOverviewData(
                    data.sort((a, b) => {
                        const aValue = Number(a[overviewDataKeyMapping[key]]);
                        const bValue = Number(b[overviewDataKeyMapping[key]]);
                        return isAsc ? aValue - bValue : bValue - aValue;
                    })
                )
            );
        } else if (page.toLowerCase() === "campaign") {
            const key = ele as CampaignDataKey;
            overallCampaignData.forEach((item: any) => {
                data.push({ ...item });
            });
            dispatch(setCampaignSortKey(campaignDataKeyMapping[key]));
            dispatch(
                setOverallCampaignData(
                    data.sort((a, b) => {
                        const aValue = Number(a[campaignDataKeyMapping[key]]);
                        const bValue = Number(b[campaignDataKeyMapping[key]]);
                        return isAsc ? aValue - bValue : bValue - aValue;
                    })
                )
            );
        }
    };

    return (
        <div className={styles.wrapper}>
            {sortDropDownElements?.map((ele: any, i: number) => {
                return (
                    <div
                        className={styles.options}
                        key={i}
                        onClick={() => handelSortClick(ele)}
                    >
                        {ele}
                    </div>
                );
            })}
        </div>
    );
};

export default Index;
