import React, { useEffect } from "react";
import styles from "./index.module.css";
import closeToast from "./../../../assets/toast/closeToast.png";
import success from "./../../../assets/toast/success.png";
import error from "./../../../assets/toast/error.png";
import warning from "./../../../assets/toast/warning.png";
interface ToastProps {
    message: string;
    type: string;
    duration?: number;
    onClose: () => void;
}

const Index: React.FC<ToastProps> = ({
    message,
    type,
    duration = 5000,
    onClose,
}) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            onClose();
        }, duration);

        return () => clearTimeout(timer);
    }, [duration, onClose]);
    console.log(message);
    return (
        <div className={styles.toastContainer}>
            <div
                className={`${styles.toast} ${
                    type === "success"
                        ? styles.successToastShadow
                        : type === "error"
                          ? styles.errorToastShadow
                          : styles.warningToastShadow
                }`}
            >
                <div
                    className={`${styles.toastProgress} ${
                        type === "success"
                            ? styles.successToastProgress
                            : type === "error"
                              ? styles.errorToastProgress
                              : styles.warningToastProgress
                    }`}
                    style={{ animationDuration: `${duration}ms` }}
                ></div>
                <div className={styles.toastHeader}>
                    <img
                        className={styles.toastIcon}
                        src={
                            type === "success"
                                ? success
                                : type === "error"
                                  ? error
                                  : warning
                        }
                        alt='type'
                    />
                    <div className={styles.toastHeading}>
                        {type === "success"
                            ? "Success!"
                            : type === "error"
                              ? "Oops! An Error"
                              : "Attention Required"}
                    </div>
                    <img
                        className={styles.toastClose}
                        src={closeToast}
                        alt='close'
                        onClick={onClose}
                    />
                </div>
                <div className={styles.toastDescription}>{message}</div>
            </div>
        </div>
    );
};

export default Index;
