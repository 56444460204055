import React, { Fragment } from "react";
import styles from "./index.module.css";
import { pageSize } from "../../../../utilServices/tableUtils";
import { setPageSize } from "../../../../redux/slice/salesSlice";
import { setOverviewPageSize } from "../../../../redux/slice/overviewSlice";
import { useDispatch } from "react-redux";

interface props {
    setDropDown: any;
    setLocalSelectedUser: any;
    user: any;
    location: any;
}

const Index: React.FC<props> = ({
    setDropDown,
    setLocalSelectedUser,
    user,
    location,
}) => {
    const dispatch = useDispatch();

    return (
        <div className={styles.dropdownWrapper}>
            {pageSize?.map((e, i) => {
                return (
                    <Fragment key={i}>
                        <div
                            className={styles.dropdownBtn}
                            onClick={() => {
                                setDropDown(false);
                                setLocalSelectedUser(user);
                                location === "sales"
                                    ? dispatch(setPageSize(e))
                                    : dispatch(setOverviewPageSize(e));
                            }}
                        >
                            {e}
                        </div>
                    </Fragment>
                );
            })}
        </div>
    );
};

export default Index;
