import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isCreateCampaignModalOpen: false,
    isCampaignsLoading: false,
    campaigns: null,
    selectedCampaign: null,
    topSectionStats: null,
    websitevisit: null,
    topProducts: null,
    totalCliks: null,
    engagements: null,
    allCreators: null,
    overallCampaignData: null,
    campaignSortKey: null,
    isUploadModalOpen: null,
};

const campaignsSlice = createSlice({
    name: "campaigns",
    initialState,
    reducers: {
        setIsCreateCampaignModalOpen: (state, action) => {
            state.isCreateCampaignModalOpen = action.payload;
        },
        setIsCampaignsLoading: (state, action) => {
            state.isCampaignsLoading = action.payload;
        },
        setCampaigns: (state, action) => {
            state.campaigns = action.payload;
        },
        setSelectedCampaign: (state, action) => {
            state.selectedCampaign = action.payload;
        },
        setTopSectionStats: (state, action) => {
            state.topSectionStats = action.payload;
        },
        setWebsiteVisit: (state, action) => {
            state.websitevisit = action.payload;
        },
        setTopProducts: (state, action) => {
            state.topProducts = action.payload;
        },
        setTotalClicks: (state, action) => {
            state.totalCliks = action.payload;
        },
        setEngagements: (state, action) => {
            state.engagements = action.payload;
        },
        setAllCreators: (state, action) => {
            state.allCreators = action.payload;
        },
        setOverallCampaignData: (state, action) => {
            state.overallCampaignData = action.payload;
        },
        setCampaignSortKey: (state, action) => {
            state.campaignSortKey = action.payload;
        },
        setIsUploadModalOpen: (state, action) => {
            state.isUploadModalOpen = action.payload;
        },
        clearCampaignsState: () => {
            return initialState;
        },
    },
});

export const {
    setIsCreateCampaignModalOpen,
    setIsCampaignsLoading,
    setCampaigns,
    setSelectedCampaign,
    setTopSectionStats,
    setWebsiteVisit,
    setTopProducts,
    setTotalClicks,
    setEngagements,
    setAllCreators,
    setOverallCampaignData,
    setCampaignSortKey,
    setIsUploadModalOpen,
    clearCampaignsState,
} = campaignsSlice.actions;
export const selectCampaigns = (state: { campaigns: any }) => state.campaigns;
export default campaignsSlice.reducer;
