import { AfterLoginRoute } from "./AfterLoginRoute";
import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./components/login";
import HelpAndPolicies from "./components/helpAndPolicies";
import ProtectedRoute from "./ProtectedRoute";
import Overview from "./components/overview";
import Sales from "./components/sales";
import Campaigns from "./components/campaigns";

function App() {
    if (process.env.NODE_ENV !== "development") {
        console.log = () => {};
        console.debug = () => {};
        console.info = () => {};
        console.warn = () => {};
    }
    return (
        <Routes>
            <Route index element={<Navigate replace to='login' />} />
            <Route
                path='login'
                element={
                    <AfterLoginRoute>
                        <Login />
                    </AfterLoginRoute>
                }
            />
            <Route
                path='overview'
                element={
                    <ProtectedRoute>
                        <Overview />
                    </ProtectedRoute>
                }
            />
            <Route
                path='campaigns'
                element={
                    <ProtectedRoute>
                        <Campaigns />
                    </ProtectedRoute>
                }
            />
            <Route
                path='sales'
                element={
                    <ProtectedRoute>
                        <Sales />
                    </ProtectedRoute>
                }
            />

            <Route
                path='policies'
                element={
                    <ProtectedRoute>
                        <HelpAndPolicies />
                    </ProtectedRoute>
                }
            />
        </Routes>
    );
}

export default App;
