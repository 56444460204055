import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ReactNode } from "react";
interface AfterLoginRouteProps {
    children: ReactNode;
}

export const AfterLoginRoute: React.FC<AfterLoginRouteProps> = ({
    children,
}) => {
    const { user } = useSelector((state: any) => state.root.auth);
    if (user?.idToken && user?.name) {
        return <Navigate to={"/overview"} />;
    }
    return <>{children}</>;
};
