export const salesSubTableHeader = [
    "Date",
    "Product",
    "Order ID",
    "Amount",
    "Content Link",
];

export const salesTableHeaders = [
    "Creator Profile",
    "Followers",
    "Total Sales",
    "Amount",
    "Faym Account",
];

export const overviewSubTableHeader = [
    "Date",
    "Content",
    "Views",
    "Likes",
    "Comment",
    "Auto DM Sent",
    "Amount",
    "Clicks",
];

export const overviewTableHeaders = [
    "Creator Profile",
    "Followers",
    "Content",
    "Views",
    "Likes",
    "Comments",
    "Auto DM Sent",
    "Amount",
    "Website Visits",
];

export const campaignTableHeader = [
    "Creator Profile",
    "Followers",
    "Views",
    "Likes",
    "Comments",
    "Eng. Rate",
    "Auto DM Sent",
    "Con. Rate",
    "Amount",
    "Website Visits",
];

export const salesSortDropDownElements = ["Followers", "Total Sales", "Amount"];
export const overviewSortDropDownElements = [
    "Followers",
    "Content",
    "Views",
    "Likes",
    "Comments",
    "Auto DM Sent",
    "Amount",
    "Website Visits",
];
export const campaignSortDropDownElements = [
    "Followers",
    "Views",
    "Likes",
    "Comments",
    "Eng. Rate",
    "Auto DM Sent",
    "Con. Rate",
    "Amount",
    "Website Visits",
];

export const monthObject: any = {
    1: "Jan",
    2: "Feb",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
};

export const pageSize = [50, 100];

export const getHeight = (dataLength: number) => {
    if (dataLength >= 1000) {
        return 54.21;
    } else if (dataLength >= 900) {
        return 54.2;
    } else if (dataLength >= 800) {
        return 54.19;
    } else if (dataLength >= 700) {
        return 54.18;
    } else if (dataLength >= 600) {
        return 54.17;
    } else if (dataLength >= 500) {
        return 54.16;
    } else if (dataLength >= 400) {
        return 54.155;
    } else if (dataLength >= 300) {
        return 54.15;
    } else if (dataLength >= 200) {
        return 54.1;
    } else {
        return 54;
    }
};
