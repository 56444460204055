import React from "react";
import styles from "./index.module.css";
import notFoundScroll from "../../../assets/sales/not-found.gif";

const index = () => {
    return (
        <div className={styles.wrapper}>
            <img src={notFoundScroll} alt='sales not found' />
            <h3>No Sales Tracked!</h3>
            <p>
                Make your first campaign live and see the results in real time
            </p>
        </div>
    );
};

export default index;
