import React, { useEffect, useState } from "react";
import SideBar from "./../common/SideBar";
import styles from "./index.module.css";
import pocEmail from "./../../assets/helpAndPolicies/pocMail.png";
import pocPhone from "./../../assets/helpAndPolicies/pocPhone.png";
import Modal from "../common/Modal";
import closeModal from "./../../assets/modal/closeModal.png";
import { useSelector } from "react-redux";
import GlobalLoader from "./../common/GlobalLoader";
import { inviteStatus, roles, roleStatusColorDictionary } from "../../enums";
import {
    addMembers,
    deleteMembers,
    getMembers,
} from "../../apiServices/memberService";
import { helpAndPoliciesPages, validateEmail } from "../../utilServices/utils";
import options from "./../../assets/helpAndPolicies/options.png";
import { verifyPermission } from "../../utilServices/permissionService";
import Logout from "../common/Logout/index";
import { getFaqs } from "../../apiServices/faqService";

const Index = () => {
    const { membersList, isHelpAndPoliciesLoading, faqs, isFaqsLoading } =
        useSelector((state: any) => state.root.helpAndPolicies);
    const { associatedBusinesses } = useSelector(
        (state: any) => state.root.overview
    );
    const { isCollapsed } = useSelector((state: any) => state.root.sidebar);
    const [selectedItem, setSelectedItem] = useState(0);
    const [showInviteModal, setShowInviteModal] = useState(false);
    const [selectedFaq, setSelectedFaq] = useState<number | null>(null);
    const [email, setEmail] = useState("");
    const [role, setRole] = useState("Member");

    useEffect(() => {
        getMembers();
    }, []);

    return (
        <div className={styles.screen}>
            <SideBar />
            {isHelpAndPoliciesLoading && <GlobalLoader />}
            {isFaqsLoading && <GlobalLoader />}
            <div
                className={styles.screenContent}
                style={isCollapsed ? { width: "95%" } : {}}
            >
                <div className={styles.header}>
                    <div className={styles.screenHeader}>Help & Policies</div>
                    <Logout />
                </div>
                <div className={styles.separator}></div>
                <div className={styles.switchSection}>
                    {helpAndPoliciesPages.map((e, i) => {
                        return (
                            <div
                                key={i}
                                className={`${styles.switchSectionItem} ${
                                    i === selectedItem &&
                                    styles.switchSectionSelectedItem
                                }`}
                                onClick={() => {
                                    setSelectedItem(i);
                                    i === 2 && getFaqs();
                                }}
                            >
                                {e}
                            </div>
                        );
                    })}
                </div>
                <div className={styles.switchContent}>
                    {selectedItem === 0 && (
                        <>
                            <div className={styles.inviteUsersContainer}>
                                <div className={styles.currentUsersHeading}>
                                    Current Users
                                </div>
                                {verifyPermission("member") && (
                                    <div
                                        className={styles.inviteUsers}
                                        onClick={() => {
                                            if (
                                                membersList.length - 2 <
                                                associatedBusinesses[0]
                                                    .membersAllowedCount
                                            )
                                                setShowInviteModal(true);
                                        }}
                                    >
                                        + Invite Users
                                    </div>
                                )}
                            </div>
                            <div className={styles.usersList}>
                                {membersList?.map(
                                    (
                                        e: any,
                                        i: React.Key | null | undefined
                                    ) => {
                                        return (
                                            <React.Fragment key={i}>
                                                <div
                                                    className={
                                                        styles.usersListItem
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.userOptionsContainer
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.usersListItemEmail
                                                            }
                                                        >
                                                            {e.email}
                                                        </div>
                                                        {roles[e.role] ===
                                                            "Member" &&
                                                            verifyPermission(
                                                                "member"
                                                            ) && (
                                                                <div
                                                                    className={
                                                                        styles.dropdown
                                                                    }
                                                                >
                                                                    <img
                                                                        className={
                                                                            styles.options
                                                                        }
                                                                        src={
                                                                            options
                                                                        }
                                                                        alt='menu'
                                                                    />
                                                                    <div
                                                                        className={
                                                                            styles.dropdownContent
                                                                        }
                                                                        onClick={() => {
                                                                            deleteMembers(
                                                                                e._id
                                                                            );
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className={
                                                                                styles.deleteUser
                                                                            }
                                                                        >
                                                                            {e.status ===
                                                                            "JOINED"
                                                                                ? "Delete User"
                                                                                : "Cancel Invite"}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.userListItemStatus
                                                        }
                                                        style={
                                                            e.status ===
                                                            "JOINED"
                                                                ? roleStatusColorDictionary[
                                                                      roles[
                                                                          e.role
                                                                      ]
                                                                  ]
                                                                : roleStatusColorDictionary[
                                                                      inviteStatus[
                                                                          e
                                                                              .status
                                                                      ]
                                                                  ]
                                                        }
                                                    >
                                                        {e.status === "JOINED"
                                                            ? roles[e.role]
                                                            : inviteStatus[
                                                                  e.status
                                                              ]}
                                                    </div>
                                                </div>
                                                {i !==
                                                    membersList.length - 1 && (
                                                    <div
                                                        className={`${styles.listSeparator} ${styles.userListSeparator}`}
                                                    ></div>
                                                )}
                                            </React.Fragment>
                                        );
                                    }
                                )}
                            </div>
                            <Modal
                                showModal={showInviteModal}
                                onClose={() => {
                                    setShowInviteModal(false);
                                }}
                                alignItems={"center"}
                            >
                                <div
                                    className={styles.inviteUsersModal}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <div className={styles.modalHeader}>
                                        <div
                                            className={
                                                styles.inviteUsersModalHeading
                                            }
                                        >
                                            Invite Team Members
                                        </div>
                                        <img
                                            className={styles.closeModal}
                                            src={closeModal}
                                            alt='close'
                                            onClick={() =>
                                                setShowInviteModal(false)
                                            }
                                        />
                                    </div>
                                    <div className={styles.inviteUsersList}>
                                        <div
                                            className={
                                                styles.inviteUsersListItem
                                            }
                                        >
                                            <div
                                                className={
                                                    styles.inviteUserInfo
                                                }
                                                style={{ width: "21.875em" }}
                                            >
                                                <div
                                                    className={
                                                        styles.inviteUserInfoLabel
                                                    }
                                                >
                                                    EMAIL ADDRESS
                                                </div>
                                                <input
                                                    className={
                                                        styles.inviteUserInfoInput
                                                    }
                                                    placeholder='Email ID here'
                                                    value={email}
                                                    onChange={(e) =>
                                                        setEmail(e.target.value)
                                                    }
                                                />
                                            </div>
                                            <div
                                                className={
                                                    styles.inviteUserInfo
                                                }
                                                style={{ width: "9.25em" }}
                                            >
                                                <div
                                                    className={
                                                        styles.inviteUserInfoLabel
                                                    }
                                                >
                                                    ROLE
                                                </div>
                                                <input
                                                    className={
                                                        styles.inviteUserInfoInput
                                                    }
                                                    placeholder='Role'
                                                    value={role}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className={styles.addAnother}>+ Add another user</div> */}
                                    <div
                                        className={styles.submit}
                                        onClick={
                                            validateEmail(email)
                                                ? () => {
                                                      addMembers(email);
                                                      setShowInviteModal(false);
                                                      setEmail("");
                                                  }
                                                : () => {}
                                        }
                                        style={
                                            validateEmail(email)
                                                ? {
                                                      background: "#2A2A2A",
                                                      color: "white",
                                                      cursor: "pointer",
                                                  }
                                                : {}
                                        }
                                    >
                                        Invite
                                    </div>
                                </div>
                            </Modal>
                        </>
                    )}

                    {selectedItem === 1 && (
                        <>
                            <div className={styles.pocName}>
                                {`Hey! ${
                                    membersList
                                        .filter(
                                            (e: any) =>
                                                roles[e.role] ===
                                                "Relationship Manager"
                                        )[0]
                                        .name.split(" ")[0]
                                } here, feel`}
                                <br></br>free to reach! 👋🏼
                            </div>
                            <div className={styles.pocContactDetails}>
                                <div className={styles.pocContact}>
                                    <img
                                        className={styles.pocContactImage}
                                        src={pocEmail}
                                        alt='email'
                                    />
                                    <div className={styles.pocContactContent}>
                                        {
                                            membersList.filter(
                                                (e: any) =>
                                                    roles[e.role] ===
                                                    "Relationship Manager"
                                            )[0].email
                                        }
                                    </div>
                                </div>
                                {membersList.filter(
                                    (e: any) =>
                                        roles[e.role] === "Relationship Manager"
                                )[0].phoneNumber && (
                                    <div className={styles.pocContact}>
                                        <img
                                            className={styles.pocContactImage}
                                            src={pocPhone}
                                            alt='phone'
                                        />
                                        <div
                                            className={styles.pocContactContent}
                                        >
                                            {
                                                membersList.filter(
                                                    (e: any) =>
                                                        roles[e.role] ===
                                                        "Relationship Manager"
                                                )[0].phoneNumber
                                            }
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                    )}

                    {selectedItem === 2 && (
                        <div className={styles.faqList}>
                            {faqs?.map((e: any, i: number) => {
                                return (
                                    <>
                                        <div
                                            key={i}
                                            className={styles.faqItem}
                                            onClick={
                                                selectedFaq === i
                                                    ? () => setSelectedFaq(null)
                                                    : () => setSelectedFaq(i)
                                            }
                                        >
                                            <div
                                                className={
                                                    styles.faqItemQuestion
                                                }
                                                style={
                                                    selectedFaq === i
                                                        ? {
                                                              fontFamily:
                                                                  "Gilroy-Bold",
                                                          }
                                                        : {}
                                                }
                                            >
                                                {e.question}
                                            </div>
                                            {selectedFaq === i && (
                                                <div
                                                    className={
                                                        styles.faqItemAnswer
                                                    }
                                                >
                                                    {e.answer}
                                                </div>
                                            )}
                                        </div>
                                        {i !== faqs.length - 1 && (
                                            <div
                                                className={`${styles.listSeparator} ${styles.faqListSeparator}`}
                                            ></div>
                                        )}
                                    </>
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Index;
