import {
    setCampaigns,
    setIsCampaignsLoading,
    setTopSectionStats,
    setWebsiteVisit,
    setTopProducts,
    setTotalClicks,
    setEngagements,
    setAllCreators,
    setOverallCampaignData,
    setCampaignSortKey,
    setIsUploadModalOpen,
} from "../redux/slice/campaignsSlice";
import store from "../redux/store";
import { getAPIData, postAPIData } from "../utilServices/utils";

function authSelect(state: { root: any }) {
    return state.root.auth;
}
function campaignSelect(state: { root: any }) {
    return state.root.campaigns;
}
function overviewSelect(state: { root: any }) {
    return state.root.overview;
}
let userData: {
    user: any;
};
let campaignsData: {
    campaigns: any;
};
let overviewData: {
    associatedBusinesses: any;
};
function listener() {
    userData = authSelect(store.getState());
    campaignsData = campaignSelect(store.getState());
    overviewData = overviewSelect(store.getState());
}
store.subscribe(listener);

export const getCampaigns = async () => {
    try {
        store.dispatch(setIsCampaignsLoading(true));
        const campaignsResponse = await getAPIData({
            url: `business/campaigns?businessId=${userData.user.businessId}`,
        });
        if (campaignsResponse.data.code === 200) {
            store.dispatch(setCampaigns(campaignsResponse.data.data));
            store.dispatch(setIsCampaignsLoading(false));
        }
        return campaignsResponse.data.data;
    } catch (error: any) {
        console.error(error.message);
        store.dispatch(setIsCampaignsLoading(false));
    } finally {
        store.dispatch(setIsCampaignsLoading(false));
    }
};

export const createCampaign = async (campaign: any) => {
    try {
        store.dispatch(setIsCampaignsLoading(true));
        const campaignsResponse = await postAPIData(
            {
                url: "business/campaigns/add",
                data: {
                    data: {
                        ...campaign,
                    },
                },
            },
            true
        );
        const campaignsCopy = campaignsData.campaigns.map((e: any) => e);
        if (campaignsResponse.data.code === 200) {
            store.dispatch(setIsCampaignsLoading(false));
            store.dispatch(
                setCampaigns([campaignsResponse.data.data, ...campaignsCopy])
            );
            window.showToast(campaignsResponse.data.message, "success");
        }
        if (campaignsResponse.data.code === 400) {
            window.showToast(campaignsResponse.data.message, "warning");
        }
    } catch (error: any) {
        console.error(error.message);
        store.dispatch(setIsCampaignsLoading(false));
    } finally {
        store.dispatch(setIsCampaignsLoading(false));
    }
};

export const getTopSectionStats = async (campaignId: any) => {
    try {
        store.dispatch(setIsCampaignsLoading(true));
        const response = await getAPIData({
            url: `business/campaigns/topStats?campaignId=${campaignId}`,
        });
        if (response.data.code === 200) {
            store.dispatch(setTopSectionStats(response?.data?.data));
            getProductSoldAndWebsiteVisit(campaignId);
            getEngagements(campaignId);
            getAllCreators(campaignId);
            getOverallData(campaignId);
        }
    } catch (error: any) {
        console.error(error.message);
    } finally {
        store.dispatch(setIsCampaignsLoading(false));
    }
};

export const getProductSoldAndWebsiteVisit = async (campaignId: any) => {
    try {
        const response = await getAPIData({
            url: `business/campaigns/topProductsStats?campaignId=${campaignId}`,
        });
        if (response.data.code === 200) {
            store.dispatch(
                setWebsiteVisit(response?.data?.data?.websiteVisits)
            );
            store.dispatch(setTopProducts(response?.data?.data?.topProducts));
            let clicks = 0;
            response?.data?.data?.topProducts?.forEach((ele: any) => {
                clicks += ele.clicks;
            });
            store.dispatch(setTotalClicks(clicks));
        }
    } catch (error: any) {
        console.error(error.message);
    } finally {
    }
};

export const getEngagements = async (campaignId: any) => {
    try {
        const response = await getAPIData({
            url: `business/campaigns/engagement?campaignId=${campaignId}`,
        });
        if (response.data.code === 200) {
            store.dispatch(setEngagements(response?.data?.data));
        }
    } catch (error: any) {
        console.error(error.message);
    } finally {
    }
};

export const getAllCreators = async (campaignId: any) => {
    try {
        const response = await getAPIData({
            url: `business/campaignCreators?campaignId=${campaignId}`,
        });
        if (response.data.code === 200) {
            store.dispatch(setAllCreators(response?.data?.data));
            // store.dispatch(setAllCreators([]));
        }
    } catch (error: any) {
        console.error(error.message);
    } finally {
    }
};

export const getOverallData = async (campaignId: any) => {
    try {
        const response = await getAPIData({
            url: `business/campaigns/overall?campaignId=${campaignId}`,
        });
        if (response.data.code === 200) {
            let data = response?.data?.data?.result;
            data = data?.map((ele: any) => {
                ele?.socials?.forEach((item: any) => {
                    if (ele.cummulativeFollowers) {
                        ele.cummulativeFollowers += item.stats.follower;
                    } else {
                        ele.cummulativeFollowers = item.stats.follower;
                    }
                });
                return ele;
            });
            store.dispatch(setOverallCampaignData(data));
            store.dispatch(setCampaignSortKey(response?.data?.data?.sortKey));
        }
    } catch (error: any) {
        console.error(error.message);
    } finally {
    }
};

export const uploadCreators = async (campaignId: any, formattedData: any) => {
    try {
        const response = await postAPIData(
            {
                url: "business/campaignCreators/add",
                data: {
                    campaignId: campaignId,
                    businessId: overviewData.associatedBusinesses[0]._id,
                    data: formattedData,
                },
            },
            true
        );
        if (response.data.code === 200) {
            store.dispatch(setIsUploadModalOpen(false));
            window.showToast(response.data.message, "success");
            setTimeout(() => {
                window.location.reload();
            }, 5000);
        } else {
            store.dispatch(setIsUploadModalOpen(false));
            window.showToast(response.data.message, "error");
        }
    } catch (error: any) {
        console.error(error.message);
    }
};
