import React from "react";
import infoIcon from "./../../../assets/campaigns/info.svg";
import { Tooltip } from "antd";

interface IndexProps {
    info: string;
}

const Index: React.FC<IndexProps> = ({ info }) => (
    <Tooltip
        title={
            <span
                dangerouslySetInnerHTML={{
                    __html: `<p>${info}</p>`,
                }}
            />
        }
        color='#343C46'
    >
        <img
            onClick={(e) => e?.stopPropagation()}
            draggable={false}
            src={infoIcon}
            alt='info-icon'
        />
    </Tooltip>
);

export default Index;
