import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SidebarState {
    selectedPage: number | null;
    isCollapsed: boolean | null;
}

const initialState: SidebarState = {
    selectedPage: window.location.href.includes("/overview")
        ? 0
        : window.location.href.includes("/campaigns")
          ? 1
          : window.location.href.includes("/sales")
            ? 2
            : window.location.href.includes("/policies")
              ? 3
              : null,
    isCollapsed: false,
};

const sidebarSlice = createSlice({
    name: "sidebar",
    initialState,
    reducers: {
        setSelectedPage: (state, action: PayloadAction<number>) => {
            state.selectedPage = action.payload;
        },
        setIsCollapsed: (state, action: PayloadAction<boolean>) => {
            state.isCollapsed = action.payload;
        },
    },
});

export const { setSelectedPage, setIsCollapsed } = sidebarSlice.actions;
export const selectSidebar = (state: { sidebar: any }) => state.sidebar;
export default sidebarSlice.reducer;
