import React, { ReactNode, useEffect } from "react";
import styles from "./index.module.css";

interface ModalProps {
    children: ReactNode;
    showModal: boolean;
    onClose: () => any;
    alignItems: string;
}

const Index: React.FC<ModalProps> = ({
    children,
    showModal,
    onClose,
    alignItems,
}) => {
    useEffect(() => {
        const body = document.querySelector("body");
        if (body) {
            body.style.overflow = showModal ? "hidden" : "auto";
        }

        return () => {
            if (body) {
                body.style.overflow = "auto";
            }
        };
    }, [showModal]);

    if (!showModal) {
        return null;
    }

    return (
        <div
            className={styles.modal}
            onClick={onClose}
            style={{
                alignItems: alignItems,
            }}
        >
            {children}
        </div>
    );
};

export default Index;
