import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ReactNode } from "react";
interface ProtectedRouteProps {
    children: ReactNode;
}
const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const { user } = useSelector((state: any) => state.root.auth);
    const isUserEmpty = !user || Object.keys(user).length === 0;

    if (isUserEmpty) {
        return <Navigate to='/' />;
    }
    return <>{children}</>;
};

export default ProtectedRoute;
