import React from "react";
import styles from "./index.module.css"; // Import the shimmer CSS

const Shimmer = () => {
    return (
        <div className={styles.shimmerWrapper}>
            <div className={styles.shimmer} />
        </div>
    );
};

export default Shimmer;
