import axios from "axios";
import store from "../redux/store";
import { dateFormats } from "../enums";

export const baseUrl = process.env.REACT_APP_API_URL;

export const helpAndPoliciesPages = ["Users", "Contact Us", "FAQ's"];

function select(state: { root: any }) {
    return state.root.auth;
}
let userData: {
    user: any;
};
function listener() {
    userData = select(store.getState());
}
store.subscribe(listener);

export const getAPIData = (data: any) => {
    return axios.get(baseUrl + "/" + data?.url, {
        params: {
            ...data.data,
        },
        headers: {
            Authorization: `Bearer ${
                data.token ? data.token : userData?.user.idToken
            }`,
        },
    });
};

export const postAPIData = (
    data: { url: string; data: any },
    authorise: boolean
) => {
    return axios.post(
        baseUrl + "/" + data?.url,
        {
            ...data?.data,
        },
        {
            headers: {
                Authorization: authorise
                    ? `Bearer ${userData?.user.idToken}`
                    : "",
            },
        }
    );
};

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const validateEmail = (email: string) => {
    return emailRegex.test(email);
};

export const dateConverter = (timeStr: string, timeFormat: string) => {
    const dateStr = timeStr?.split(".")[0];
    const date = new Date(dateStr);
    const istDate = new Date(date.getTime() + 5.5 * 60 * 60 * 1000);
    let day;
    let month;
    let year;
    switch (timeFormat) {
        case dateFormats.longDate:
            const monthNames = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ];
            day = istDate.getDate();
            month = monthNames[istDate.getMonth()];
            year = istDate.getFullYear();
            const dayWithSuffix =
                day +
                (day % 10 === 1 && day !== 11
                    ? "st"
                    : day % 10 === 2 && day !== 12
                      ? "nd"
                      : day % 10 === 3 && day !== 13
                        ? "rd"
                        : "th");
            return `${dayWithSuffix} ${month} ${year}`;
        case dateFormats.yyyyMmDd:
            year = istDate.getFullYear();
            month = String(istDate.getMonth() + 1).padStart(2, "0");
            day = String(istDate.getDate()).padStart(2, "0");
            return `${year}-${month}-${day}`;
        case dateFormats.ddMmYy:
            day = String(istDate.getDate()).padStart(2, "0");
            month = String(istDate.getMonth() + 1).padStart(2, "0");
            year = String(istDate.getFullYear()).slice(-2);
            return `${day}/${month}/${year}`;
        case dateFormats.yyyy_mm_dd:
            day = String(istDate.getDate()).padStart(2, "0");
            month = String(istDate.getMonth() + 1).padStart(2, "0");
            year = String(istDate.getFullYear());
            return `${year}.${month}.${day}`;
        default:
            break;
    }
};

export const getShortNumber = (number: number) => {
    if (!number || number === 0 || number === null) {
        return "-";
    } else if (Math.abs(number) >= 1_000_000_000) {
        return (number / 1_000_000_000).toFixed(1).replace(/\.0$/, "") + "B";
    } else if (Math.abs(number) >= 1_000_000) {
        return (number / 1_000_000).toFixed(1).replace(/\.0$/, "") + "M";
    } else if (Math.abs(number) >= 1_000) {
        return (number / 1_000).toFixed(1).replace(/\.0$/, "") + "K";
    } else {
        return number.toString();
    }
};

export const backgroundList = [
    "#5D4037",
    "#512DA8",
    "#004D3F",
    "#A142F8",
    "#EE6E01",
    "#C0399F",
    "#7CA00D",
    "#D08015",
    "#4286F5",
    "#10828F",
    "#B82424",
    "#637A88",
    "#DA1F77",
    "#FFBE00",
    "#EB7181",
];
