import React, { Fragment } from "react";
import styles from "./index.module.css";
import { dateConverter } from "../../../utilServices/utils";
import { dateFormats } from "../../../enums";

interface props {
    setSelectedRow: any;
    subTableHeader: any;
    userWiseSales: any;
    selectedUser: any;
}

const index: React.FC<props> = ({
    setSelectedRow,
    subTableHeader,
    userWiseSales,
    selectedUser,
}) => {
    return (
        <div className={styles.wrapper}>
            <table className={styles.subTable}>
                <thead>
                    <tr className={styles.creatorPerformanceListColumns}>
                        {subTableHeader?.map((e: any, i: number) => {
                            return (
                                <th key={i} className={styles.columnName}>
                                    {e}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {userWiseSales?.map((e: any, i: number) => {
                        return (
                            <Fragment key={i}>
                                <tr className={styles.creatorProductList}>
                                    <td>
                                        {dateConverter(
                                            e?.orderPlacedOn,
                                            dateFormats.yyyy_mm_dd
                                        )}
                                    </td>
                                    <td>
                                        {e?.productInfo?.productName?.length >
                                        50
                                            ? e?.productInfo?.productName?.slice(
                                                  0,
                                                  50
                                              ) + "..."
                                            : e?.productInfo?.productName}
                                    </td>
                                    <td>{e?.platformId}</td>
                                    <td>{e?.productInfo?.productPrice}</td>
                                    <td>
                                        {e?.contentLink &&
                                        e?.contentLink !== "" ? (
                                            <a
                                                href={e?.contentLink}
                                                target='_blank'
                                                rel='noreferrer'
                                            >
                                                Link
                                            </a>
                                        ) : (
                                            "-"
                                        )}
                                    </td>
                                </tr>
                            </Fragment>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default index;
