export const roles: {
    [key: string]: string;
} = {
    RELATIONSHIP_MANAGER: "Relationship Manager",
    ADMIN: "Admin",
    MEMBER: "Member",
} as const;

export const inviteStatus: {
    [key: string]: string;
} = {
    PENDING: "Invite Sent",
    JOINED: "Joined",
    EXPIRED: "Expired",
} as const;

export const roleStatusColorDictionary: {
    [key: string]: { color: string; backgroundColor: string };
} = {
    Admin: { color: "white", backgroundColor: "#2A2A2A" },
    "Invite Sent": { color: "#63460F", backgroundColor: "#FFC700" },
    Member: { color: "white", backgroundColor: "#0066FF" },
    "Relationship Manager": { color: "white", backgroundColor: "green" },
    Expired: { color: "white", backgroundColor: "red" },
};

export const dateFormats = {
    longDate: "Long Date",
    ddMmYy: "DD/MM/YY",
    yyyyMmDd: "YYYY-MM-DD",
    yyyy_mm_dd: "YYYY.MM.DD",
};
