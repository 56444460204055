import React, { Fragment } from "react";
import styles from "./index.module.css";
import { dateConverter, getShortNumber } from "../../../utilServices/utils";
import { dateFormats } from "../../../enums";

interface props {
    subTableHeader: any;
    userWiseSales: any;
}

const index: React.FC<props> = ({ subTableHeader, userWiseSales }) => {
    return (
        <div className={styles.wrapper}>
            <table className={styles.subTable}>
                <thead>
                    <tr className={styles.creatorPerformanceListColumns}>
                        {subTableHeader?.map((e: any, i: number) => {
                            return (
                                <th key={i} className={styles.columnName}>
                                    {e}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {userWiseSales?.map((e: any, i: number) => {
                        return (
                            <Fragment key={i}>
                                <tr className={styles.creatorProductList}>
                                    <td>
                                        {dateConverter(
                                            e?.orderPlacedOn,
                                            dateFormats.yyyy_mm_dd
                                        )}
                                    </td>
                                    <td>
                                        {e.contentLink &&
                                        e.contentLink !== "" ? (
                                            <a
                                                href={e.contentLink}
                                                target='_blank'
                                                rel='noreferrer'
                                            >
                                                Link
                                            </a>
                                        ) : (
                                            "-"
                                        )}
                                    </td>
                                    <td>{getShortNumber(e.views)}</td>
                                    <td>{getShortNumber(e.likes)}</td>
                                    <td>{getShortNumber(e.comments)}</td>
                                    <td>{getShortNumber(e.dms)}</td>
                                    <td>
                                        {getShortNumber(
                                            e.productInfo.productPrice
                                        )}
                                    </td>
                                    <td>{getShortNumber(e.clicks)}</td>
                                </tr>
                            </Fragment>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default index;
