import React from "react";
import LeftContainer from "./LeftContainer";
import RightContainer from "./RightContainer";
import styles from "./index.module.css";
import Modal from "./../common/Modal";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setShowLoginErrorModal } from "../../redux/slice/authSlice";
import loginAgain from "./../../assets/login/loginAgain.gif";
import closeModal from "./../../assets/modal/closeModal.png";
import { googleSignIn } from "../../apiServices/authenticationService";

const Index = () => {
    const { showLoginErrorModal, loginMessage } = useSelector(
        (state: any) => state.root.auth
    );
    const dispatch = useDispatch();
    return (
        <>
            <div className={styles.loginContainer}>
                <LeftContainer />
                <RightContainer />
                <Modal
                    onClose={() => {
                        dispatch(setShowLoginErrorModal(false));
                    }}
                    showModal={showLoginErrorModal}
                    alignItems='center'
                >
                    <div
                        className={styles.loginErrorModal}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <img
                            className={styles.closeModal}
                            src={closeModal}
                            alt='close'
                            onClick={() => {
                                dispatch(setShowLoginErrorModal(false));
                            }}
                        />
                        <img
                            className={styles.loginErrorGif}
                            src={loginAgain}
                            alt='error'
                        />
                        <div className={styles.modalHeading}>
                            Whoops! Error Signing Up
                        </div>
                        <div className={styles.loginMessage}>
                            {loginMessage}
                        </div>
                        <div
                            className={styles.tryAgainButton}
                            onClick={() => {
                                googleSignIn();
                                dispatch(setShowLoginErrorModal(false));
                            }}
                        >
                            Try Again
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default Index;
