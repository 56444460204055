import React, { useMemo } from "react";
import styles from "./index.module.css";
import { backgroundList } from "../../../utilServices/utils";

interface Name {
    name: string;
    size: any;
    fontSize: any;
    userBackgroundIndex: any;
}

const Index: React.FC<Name> = ({
    name,
    size,
    fontSize,
    userBackgroundIndex,
}) => {
    const getInitials = (name: any) => {
        const initials = name?.split(" ");
        let finalInitials = "";
        if (initials?.length) finalInitials += initials[0][0];
        if (initials?.length > 1 && initials[1][0] !== undefined) {
            finalInitials += initials[1][0];
        }
        const backgroundIndex = Math.floor(
            Math.random() * backgroundList.length
        );
        return { finalInitials, backgroundIndex };
    };

    const { finalInitials, backgroundIndex } = useMemo(
        () => getInitials(name),
        [name]
    );

    return (
        <div
            style={{
                background:
                    backgroundList[
                        userBackgroundIndex
                            ? userBackgroundIndex
                            : backgroundIndex
                    ],
                color: "white",
                minWidth: size,
                height: size,
                fontSize: fontSize,
            }}
            className={styles.initialsWrapper}
        >
            {finalInitials}
        </div>
    );
};

export default Index;
