import React from "react";
import styles from "./index.module.css";

const Index = ({ length }: { length: string }) => {
    return (
        <div
            className={styles.loader}
            style={length ? { height: length, width: length } : {}}
        />
    );
};

export default Index;
