import React from "react";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import likes from "./../../assets/overview/likes.png";
import comments from "./../../assets/overview/comments.png";
import shares from "./../../assets/campaigns/shares.png";

interface pieChartData {
    data: any;
}

const CustomTooltip = ({ active, payload, colors }: any) => {
    if (active && payload && payload.length) {
        const color = payload[0].payload.fill || colors[payload[0].name];
        return (
            <div
                className='custom-tooltip'
                style={{
                    backgroundColor: "white",
                    padding: "8px",
                    borderRadius: "0.3125em",
                    color: color,
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.15)",
                }}
            >
                <p
                    style={{
                        margin: 0,
                        fontFamily: "Gilroy-Medium",
                        fontSize: "0.8rem",
                    }}
                >{`${payload[0].payload.title}: ${payload[0].payload.value} `}</p>
            </div>
        );
    }
    return null;
};

const SemiDonutChart: React.FC<pieChartData> = ({ data }) => {
    const COLORS = data.map((e: any) => e.color);
    return (
        <PieChart width={400} height={274}>
            <Pie
                data={data}
                startAngle={180}
                endAngle={0}
                innerRadius={80}
                outerRadius={137}
                fill='#8884d8'
                dataKey='value'
            >
                {data.map((e: any, i: number) => (
                    <Cell key={`cell-${i}`} fill={COLORS[i % COLORS.length]} />
                ))}
            </Pie>
            <Tooltip content={<CustomTooltip colors={COLORS} />} />
        </PieChart>
    );
};

export default SemiDonutChart;
