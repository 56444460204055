import { getAPIData } from "../utilServices/utils";
import store from "../redux/store";
import {
    setCreatorsData,
    setSalesLoading,
    setTopCreators,
    setTopProducts,
    setMonthlySales,
    setTotalSalesAmount,
    setOverallSalesData,
    setUserWiseSalesData,
    setTotalPages,
    setTableLoading,
    setSortSelectedKey,
    setSalesTableColumns,
} from "../redux/slice/salesSlice";

function select(state: { root: any }) {
    return state.root.auth;
}
let userData: {
    user: any;
};
function listener() {
    userData = select(store.getState());
}
store.subscribe(listener);

export const getCampaignCreators = async (campaignId: any) => {
    store.dispatch(setSalesLoading(true));
    try {
        const response = await getAPIData({
            url: `business/campaignCreators?businessId=${userData?.user?.businessId}&campaignId=${campaignId}`,
        });
        if (response?.data?.code === 200) {
            store.dispatch(setCreatorsData(response?.data?.data));
        }
    } catch (error) {
        console.error(error);
    } finally {
        store.dispatch(setSalesLoading(false));
    }
};

export const getCumulativeSalesData = async (
    faymUserId: any,
    startDate: any,
    endDate: any,
    campaignId: any
) => {
    try {
        const response = await getAPIData({
            url: `business/campaignSales/cumulative?businessId=${userData?.user?.businessId}&faymUserId=${faymUserId}&startDate=${startDate}&endDate=${endDate}&campaignId=${campaignId}`,
        });
        if (response?.data?.code === 200) {
            store.dispatch(setTopCreators(response?.data?.data?.topCreators));
            store.dispatch(setTopProducts(response?.data?.data?.topProducts));
            const monthlySales = response?.data?.data?.nMonthsSalesTotal?.sort(
                (a: any, b: any) => a._id - b._id
            );
            store.dispatch(setMonthlySales(monthlySales));
            let totalAmount = 0;
            monthlySales.forEach((ele: any) => {
                totalAmount += Math.round(ele.totalCommission);
            });
            store.dispatch(setTotalSalesAmount(totalAmount));
        }
    } catch (error) {
        console.error(error);
    }
};

export const getOverallSalesData = async (startDate: any, endDate: any) => {
    store.dispatch(setSalesLoading(true));
    try {
        const response = await getAPIData({
            url: `business/campaignSales/overall?businessId=${userData?.user?.businessId}&startDate=${startDate}&endDate=${endDate}`,
        });
        if (response?.data?.code === 200) {
            let data = response?.data?.data?.result;
            data = data?.map((ele: any) => {
                ele?.socials?.forEach((item: any) => {
                    if (ele.cummulativeFollowers) {
                        ele.cummulativeFollowers += item.stats.follower;
                    } else {
                        ele.cummulativeFollowers = item.stats.follower;
                    }
                });
                return ele;
            });
            store.dispatch(setOverallSalesData(data));
            store.dispatch(setSortSelectedKey(response?.data?.data?.sortKey));
            store.dispatch(
                setSalesTableColumns(response?.data?.data?.columnMap)
            );
        }
    } catch (error) {
        console.error(error);
    } finally {
        store.dispatch(setSalesLoading(false));
    }
};

export const getUserWiseSalesData = async (
    startDate: any,
    endDate: any,
    faymUserId: string,
    pageSize: number,
    pageNumber: number
) => {
    // store.dispatch(setSalesLoading(true));
    store.dispatch(setTableLoading(true));
    try {
        const response = await getAPIData({
            url: `business/campaignSales/creatorwise?businessId=${userData?.user?.businessId}&startDate=${startDate}&endDate=${endDate}&faymUserId=${faymUserId}&pageSize=${pageSize}&pageNum=${pageNumber}`,
        });
        if (response?.data?.code === 200) {
            store.dispatch(setUserWiseSalesData(response?.data?.data?.result));
            store.dispatch(setTotalPages(response?.data?.data?.totalPages));
        }
    } catch (error) {
        console.error(error);
    } finally {
        store.dispatch(setTableLoading(false));
    }
};
