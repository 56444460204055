import {
    setIsHelpAndPoliciesLoading,
    setMembersList,
    updateMembersList,
} from "../redux/slice/helpAndPoliciesSlice";
import store from "../redux/store";
import { getAPIData, postAPIData } from "../utilServices/utils";

function select(state: { root: any }) {
    return state.root.auth;
}
function helpAndPolciesSelect(state: { root: any }) {
    return state.root.helpAndPolicies;
}
let userData: {
    user: any;
};
let helpAndPoliciesData: {
    membersList: any;
};
function listener() {
    userData = select(store.getState());
    helpAndPoliciesData = helpAndPolciesSelect(store.getState());
}
store.subscribe(listener);

export const getMembers = async () => {
    try {
        store.dispatch(setIsHelpAndPoliciesLoading(true));
        const membersResponse = await getAPIData({
            url: `business/members?businessId=${userData.user.businessId}`,
            // url: `business/members?businessId=${userData.user.businessId}&id=${userData.user._id}`,
        });
        if (membersResponse.data.code === 200) {
            store.dispatch(setMembersList(membersResponse.data.data));
            store.dispatch(setIsHelpAndPoliciesLoading(false));
        }
    } catch (error: any) {
        console.error(error.message);
        store.dispatch(setIsHelpAndPoliciesLoading(false));
    } finally {
        store.dispatch(setIsHelpAndPoliciesLoading(false));
    }
};

export const addMembers = async (email: string) => {
    try {
        store.dispatch(setIsHelpAndPoliciesLoading(true));
        const addMembersResponse = await postAPIData(
            {
                url: "business/members/add",
                data: {
                    businessId: userData.user.businessId,
                    businessMemberData: [
                        { email: email, invitedBy: userData.user.email },
                    ],
                },
            },
            true
        );
        store.dispatch(updateMembersList(addMembersResponse.data.data[0]));
        store.dispatch(setIsHelpAndPoliciesLoading(false));
    } catch (error) {
        store.dispatch(setIsHelpAndPoliciesLoading(false));
    } finally {
        store.dispatch(setIsHelpAndPoliciesLoading(false));
    }
};

export const deleteMembers = async (memberId: string) => {
    try {
        store.dispatch(setIsHelpAndPoliciesLoading(true));
        const deleteMembersResponse = await postAPIData(
            {
                url: "business/members/delete",
                data: {
                    businessId: userData.user.businessId,
                    memberId: memberId,
                },
            },
            true
        );
        if (deleteMembersResponse.data.code === 200) {
            const membersList = helpAndPoliciesData.membersList.filter(
                (e: any) => e._id !== memberId
            );
            store.dispatch(setMembersList(membersList));
        }
        store.dispatch(setIsHelpAndPoliciesLoading(false));
    } catch (error) {
        store.dispatch(setIsHelpAndPoliciesLoading(false));
    } finally {
        store.dispatch(setIsHelpAndPoliciesLoading(false));
    }
};
