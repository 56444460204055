import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./slice/authSlice";
import sidebarReducer from "./slice/sidebarSlice";
import helpAndPoliciesReducer from "./slice/helpAndPoliciesSlice";
import overviewReducer from "./slice/overviewSlice";
import salesReducer from "./slice/salesSlice";
import campaignsReducer from "./slice/campaignsSlice";

const rootReducer = combineReducers({
    auth: authReducer,
    sidebar: sidebarReducer,
    overview: overviewReducer,
    helpAndPolicies: helpAndPoliciesReducer,
    campaigns: campaignsReducer,
    sales: salesReducer,
});

export default rootReducer;
