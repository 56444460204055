import React, { Fragment, useEffect, useState, useRef } from "react";
import styles from "./index.module.css";
import tableItemDropdownClose from "./../../../assets/overview/tableItemDropdownClose.png";
import tableItemDropdownOpen from "./../../../assets/overview/tableItemDropdownOpen.png";
import dropDownIcon from "./../../../assets/overview/dropDownIcon.png";
import SubTable from "../SubTables/salesSubTable";
import NameInitials from "./../NameInitials/index";
import DropDown from "../SubTables/DropDown/index";
import { useSelector, useDispatch } from "react-redux";
import { setPageNumber, setPageSize } from "../../../redux/slice/salesSlice";
import { getUserWiseSalesData } from "../../../apiServices/salesService";
import { getHeight } from "../../../utilServices/tableUtils";
import { getShortNumber } from "../../../utilServices/utils";

interface props {
    isExpandable: boolean;
    userWiseSales: any;
    overallSalesData: any;
    tableHeaders: any;
    subTableHeader: any;
}

const Index: React.FC<props> = ({
    isExpandable,
    userWiseSales,
    overallSalesData,
    tableHeaders,
    subTableHeader,
}) => {
    const [selectedRow, setSelectedRow] = useState<number | null>(null);
    const [dropDown, setDropDown] = useState(false);
    const {
        pageSize,
        pageNumber,
        startDate,
        endDate,
        totalPages,
        isTableLoading,
    } = useSelector((state: any) => state.root.sales);
    const dispatch = useDispatch();
    const [localSelectedUser, setLocalSelectedUser] = useState(null);
    const dropDownRef = useRef<HTMLDivElement>(null);

    const getUserSalesData = (faymUserId: any) => {
        getUserWiseSalesData(
            startDate,
            endDate,
            faymUserId,
            pageSize,
            pageNumber
        );
    };

    useEffect(() => {
        if (localSelectedUser === null) return;
        getUserWiseSalesData(
            startDate,
            endDate,
            localSelectedUser,
            pageSize,
            pageNumber
        );
    }, [pageNumber, pageSize, startDate, endDate, overallSalesData]);

    const handleClickOutside = (event: MouseEvent) => {
        if (
            dropDownRef.current &&
            !dropDownRef.current.contains(event.target as Node)
        ) {
            setDropDown(false);
        }
    };

    useEffect(() => {
        window.addEventListener("mousedown", handleClickOutside);
        return () => {
            window.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <>
            <table>
                <thead>
                    <tr className={styles.creatorPerformanceListColumns}>
                        {tableHeaders?.map((e: any, i: number) => {
                            return (
                                <th key={i} className={styles.columnName}>
                                    {e}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {overallSalesData?.map((e: any, i: number) => {
                        return (
                            <Fragment key={i}>
                                <tr
                                    className={`${
                                        styles.creatorPerformanceListItem
                                    } ${selectedRow === i && styles.selectedRow}`}
                                    onClick={() => {
                                        selectedRow === null &&
                                            getUserSalesData(e?._id);
                                        isExpandable && selectedRow === i
                                            ? setSelectedRow(null)
                                            : setSelectedRow(i);
                                        dispatch(setPageNumber(1));
                                        dispatch(setPageSize(50));
                                    }}
                                >
                                    <td>
                                        <div
                                            className={
                                                styles.creatorDetailsContent
                                            }
                                        >
                                            {e.profileImgUrl !== "" &&
                                            e.profileImgUrl ? (
                                                <img
                                                    className={
                                                        styles.creatorDetailImage
                                                    }
                                                    src={e.profileImgUrl}
                                                    alt='creator-image'
                                                />
                                            ) : (
                                                <div
                                                    style={{
                                                        marginRight: "0.625em",
                                                        marginLeft: "1.25em",
                                                    }}
                                                >
                                                    <NameInitials
                                                        name={
                                                            e.name
                                                                ? e.name
                                                                : "faym user"
                                                        }
                                                        size={"38px"}
                                                        fontSize={"1rem"}
                                                        userBackgroundIndex={
                                                            null
                                                        }
                                                    />
                                                </div>
                                            )}
                                            <div
                                                className={
                                                    styles.creatorDetails
                                                }
                                            >
                                                <div
                                                    className={
                                                        styles.creatorDetailName
                                                    }
                                                >
                                                    {e.name}
                                                </div>
                                                <div
                                                    className={
                                                        styles.creatorStoreLink
                                                    }
                                                >
                                                    <a
                                                        href={e.faymWall}
                                                        target='_blank'
                                                        rel='noreferrer'
                                                    >
                                                        @{e.userName}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        {e?.socials?.map(
                                            (item: any, index: number) => {
                                                return (
                                                    item.platformName ===
                                                        "INSTAGRAM" && (
                                                        <div
                                                            className={
                                                                styles.followersContainer
                                                            }
                                                            key={index}
                                                        >
                                                            <img
                                                                className={
                                                                    styles.socialMediaImage
                                                                }
                                                                src={
                                                                    item.platformIcon
                                                                }
                                                                alt={
                                                                    item.platformName
                                                                }
                                                            />
                                                            <div
                                                                className={
                                                                    styles.tableData
                                                                }
                                                            >
                                                                {getShortNumber(
                                                                    item.stats
                                                                        .followers
                                                                )}
                                                            </div>
                                                        </div>
                                                    )
                                                );
                                            }
                                        )}
                                    </td>
                                    <td>{getShortNumber(e.totalSales)}</td>
                                    <td>
                                        {getShortNumber(e.totalSalesAmount)}
                                    </td>
                                    <td className={styles.dropdownContainer}>
                                        <div className={styles.dropdownContent}>
                                            <div className={styles.tableData}>
                                                @{e.userName}
                                            </div>
                                            {isExpandable && (
                                                <img
                                                    className={
                                                        styles.itemDropdown
                                                    }
                                                    src={
                                                        selectedRow === i
                                                            ? tableItemDropdownOpen
                                                            : tableItemDropdownClose
                                                    }
                                                    alt='item-dropdown'
                                                />
                                            )}
                                        </div>
                                    </td>
                                </tr>
                                {selectedRow === i && (
                                    <tr
                                        className={styles.subtableRow}
                                        style={
                                            selectedRow === i
                                                ? {
                                                      height: `${
                                                          230 +
                                                          (userWiseSales?.length
                                                              ? userWiseSales?.length -
                                                                1
                                                              : 1) *
                                                              getHeight(
                                                                  userWiseSales?.length
                                                              )
                                                      }px `,
                                                  }
                                                : {}
                                        }
                                    >
                                        <td className={styles.subtable}>
                                            <SubTable
                                                setSelectedRow={setSelectedRow}
                                                userWiseSales={userWiseSales}
                                                subTableHeader={subTableHeader}
                                                selectedUser={e}
                                            />
                                        </td>
                                        {selectedRow === i && (
                                            <tr>
                                                <td>
                                                    <div
                                                        className={
                                                            styles.footer
                                                        }
                                                    >
                                                        <div>
                                                            Page {pageNumber} of{" "}
                                                            {totalPages}
                                                        </div>
                                                        <div
                                                            className={
                                                                styles.btnParentWrapper
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    styles.btnWrapper
                                                                }
                                                            >
                                                                Page:{" "}
                                                                {pageNumber}
                                                                <div
                                                                    className={
                                                                        styles.btnGroup
                                                                    }
                                                                >
                                                                    <button
                                                                        disabled={
                                                                            pageNumber <=
                                                                            1
                                                                        }
                                                                        onClick={() => {
                                                                            dispatch(
                                                                                setPageNumber(
                                                                                    pageNumber -
                                                                                        1
                                                                                )
                                                                            );
                                                                            setLocalSelectedUser(
                                                                                e._id
                                                                            );
                                                                        }}
                                                                        style={
                                                                            pageNumber <=
                                                                            1
                                                                                ? {
                                                                                      cursor: "not-allowed",
                                                                                  }
                                                                                : {
                                                                                      cursor: "pointer",
                                                                                  }
                                                                        }
                                                                    >
                                                                        -
                                                                    </button>
                                                                    <button
                                                                        disabled={
                                                                            pageNumber >=
                                                                            totalPages
                                                                        }
                                                                        onClick={() => {
                                                                            dispatch(
                                                                                setPageNumber(
                                                                                    pageNumber +
                                                                                        1
                                                                                )
                                                                            );
                                                                            setLocalSelectedUser(
                                                                                e._id
                                                                            );
                                                                        }}
                                                                        style={
                                                                            pageNumber >=
                                                                            totalPages
                                                                                ? {
                                                                                      cursor: "not-allowed",
                                                                                  }
                                                                                : {
                                                                                      cursor: "pointer",
                                                                                  }
                                                                        }
                                                                    >
                                                                        +
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div
                                                                style={{
                                                                    position:
                                                                        "relative",
                                                                }}
                                                                ref={
                                                                    dropDownRef
                                                                }
                                                            >
                                                                <button
                                                                    className={`${styles.dropDownBtn} ${
                                                                        dropDown &&
                                                                        styles.dropDown
                                                                    }`}
                                                                    onClick={() =>
                                                                        setDropDown(
                                                                            !dropDown
                                                                        )
                                                                    }
                                                                >
                                                                    Page Size:{" "}
                                                                    {pageSize}
                                                                    <img
                                                                        src={
                                                                            dropDownIcon
                                                                        }
                                                                        alt='drop down icon'
                                                                    />
                                                                </button>
                                                                {dropDown && (
                                                                    <DropDown
                                                                        setDropDown={
                                                                            setDropDown
                                                                        }
                                                                        setLocalSelectedUser={
                                                                            setLocalSelectedUser
                                                                        }
                                                                        user={
                                                                            e._id
                                                                        }
                                                                        location={
                                                                            "sales"
                                                                        }
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tr>
                                )}
                            </Fragment>
                        );
                    })}
                </tbody>
            </table>
            {overallSalesData?.length === 0 && (
                <div
                    className={styles.noDataAvailable}
                    style={{ justifyContent: "center" }}
                >
                    No Data Available
                </div>
            )}
        </>
    );
};

export default Index;
